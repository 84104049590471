<route>
{
  "meta": {
    "permission": [
      "product.view_producttemplate"
    ]
  }
}
</route>

<template>
  <div class="px-1 px-lg-2">
    <i-table
      app="product.producttemplate"
      api="product.template"
      :dontRemove="!isAdmin"
      :headers="headers"
      :opt="{ company: company.pk }"
      :otherBtn="templateBtn.addTemplate"
      :reload.sync="reload"
      :title="$tc('template', 2)"
      @click:create="changeRoute({ pk: 'create' })"
      @click:edit="changeRoute"
    >
      <template v-slot:otherBtn="{ item }">
        <i-btn
          classes="mx-1"
          color="secondary"
          icon="fa-clone"
          outlined
          :title="$tc('clone', 1)"
          @click="clone(item.pk)"
        />
      </template>
    </i-table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    headers() {
      return [
        {
          text: this.$t('name'),
          value: 'name'
        },
        {
          text: this.$t('action'),
          value: 'actions',
          align: 'center',
          width: '160px'
        }
      ]
    },
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin',
      company: 'company/getCompanyData'
    }),
    /**
     * templateBtn
     * Computado para permisos para agregar o clonar
     * plantillas de proceso de producción
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    templateBtn() {
      return {
        addTemplate:
          this.getPermissions([`product.add_producttemplate`]) || this.isAdmin
      }
    }
  },
  data() {
    return {
      reload: false,
      dialog: false,
      toEdit: null,
      reload2: false,
      dialog2: false,
      toEdit2: null
    }
  },
  methods: {
    changeRoute(item) {
      this.$router.push({
        name: 'templatesProduct-pk',
        params: { pk: this.codification({ code: item.pk }) }
      })
    },
    async clone(pk) {
      const response = await this.$api.product.template.show({
        pk: pk,
        opt: { params: { clone: true } }
      })
      this.changeRoute({ pk: response.data.pk })
    }
  }
}
</script>
<style lang="sass">
.template-list > p
  margin-bottom: 0
</style>
